import { graphql } from "gatsby";
import React from "react";
import HomepageComponent from "../components/homepage";

const Homepage = ({ data }: any) => {
  const mergedPosts = data.allMdx.nodes.map(
    (post, index: number) => {
      return {
        body: post.body,
        ...post.frontmatter,
        index
      };
    }
  );
  return <HomepageComponent posts={mergedPosts} />;
};

export default Homepage;

export const query = graphql`
query (
  $formatString:String!

){
  allMdx(sort: {fields: frontmatter___date, order: DESC}) {
    nodes {
      frontmatter {
        title
        date(formatString:$formatString)
        slug
        author
        email
        category
        description
        tags
      }
    }
  }
}

`;
