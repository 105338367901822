/** @jsx jsx */
import { jsx } from "theme-ui";

//TODO: react-router-dom 적용
import Layout from "./layout";
import Listing from "./list/listing";


import TopBanner from "./banner/top-banner";
import Seo from "./seo";
// @ts-ignore
const cateNameList = {
  engineering: "엔지니어링",
  data: "데이터",
  design: "디자인",
  culture: "컬쳐",
};

type PostsProps = {
  posts: {
    title: string
    date: string
    slug: string
    author: string
    email: string
    category: string
    description: string
    tags?: {
      name: string
      slug: string
    }[]
    index: number
    body: string
  }[];
};
const Homepage = ({ posts }: PostsProps) => {

  if (typeof window !== "undefined") {
    const location = window.location;

    let category = "engineering";
    if (location && "search" in location) {
      if (location.search.includes("category")) {
        category = location.search.split("?")[1].split("category=")[1];
      }
    }

    return (
      <Layout selectedCategory={category}>
        <Seo />
        <div className="">
          <div className="pt-5 px-auto mx-5">
            <TopBanner />
          </div>
          <div className="w-full  px-5 md:px-10">
            {
              <div className="mt-[80px] md:max-w-md lg:max-w-lg mx-auto">
                <Listing
                  posts={posts}
                  showTags={false}
                  selectedCategory={category}
                // {category}
                />
              </div>

            }
          </div>
        </div>
      </Layout>
    );
  } else {
    return null;
  }
};

export default Homepage;


