/** @jsx jsx */
import * as React from "react";
import { jsx, Link as TLink, Box } from "theme-ui";
import { Link } from "gatsby";
import Gravatar from "react-gravatar";
import listImg01 from "/static/img/list/image_01.png";
import listImg02 from "/static/img/list/image_02.png";
import listImg03 from "/static/img/list/image_03.png";
import listImg04 from "/static/img/list/image_04.png";
import listImg05 from "/static/img/list/image_05.png";
import listImg06 from "/static/img/list/image_06.png";
import listImg07 from "/static/img/list/image_07.png";
import listImg08 from "/static/img/list/image_08.png";
import listImg09 from "/static/img/list/image_09.png";
import listImg10 from "/static/img/list/image_10.png";

const imgList = [
  listImg01,
  listImg02,
  listImg03,
  listImg04,
  listImg05,
  listImg06,
  listImg07,
  listImg08,
  listImg09,
  listImg10,
];

type BlogListItemProps = {
  post: {
    index: number;
    slug: string;
    title: string;
    date: string;
    excerpt: string;
    description: string;
    timeToRead?: number;
    body: string;
    tags?: {
      name: string;
      slug: string;
    }[];

  }
  showTags?: boolean;
  index: number;
  selectedCategory?: string;
};

const BlogListItem = ({
  post,
  showTags = true,
}: BlogListItemProps) => {
  const imgNumber = post.index % 10

  return (
    <Box>
      <div
        className="
      group 
      flex-col-reverse
      flex
      gap-x-[60px]
      md:flex-row
      mb-[80px]
      
      "
      >
        <div
          style={{ flex: 8 }}
          className="flex flex-col justify-center mt-[28px] md:mt-[0px]"
        >
          <Link
            to={post.slug}
            className="flex flex-col gap-5
          "
          >
            <h2
              className="
              text-title-sm 
              font-semibold	
              md:text-[32px] md:mt-0
              text-[#3A3B40]
              group-hover:text-green-200 
              leading-[140%]
              
              "
            >
              {post.title}
            </h2>

            <p
              className="text-[17px] text-[#65666B] font-normal"
              style={{ lineHeight: "150%" }}
            >
              {post.description}
            </p>
            <span style={{ color: "#A6A8AD" }}>
              <time>
                {post.date
                  .replace(/["년","월"]/gi, ". ")
                  .replace(/일/gi, " · ")}
              </time>
              {post.author}
            </span>
          </Link>
        </div>
        <div
          sx={{
            color: `secondary`,
            mt: 1,
            a: { color: `secondary` },
            fontSize: [1, 1, 1],
          }}
          className="hidden md:block
          transition ease-in-out delay-100 group-hover:-translate-y-3  duration-300
          
          "
        >
          {post &&
            post.author &&
            post.email && (
              <div>
                <div
                  className="flex flex-row 
              
              
              "
                  style={{ flex: 2 }}
                >
                  <img
                    src={
                      imgList[imgNumber]
                    }
                    style={{ clipPath: "circle()", objectFit: "cover" }}
                    className="block w-[200px] h-[200px]"
                  />
                </div>
                {/* <Gravatar size={220} email={post.frontmatter.email} style={{ borderRadius: "50%", clipPath: "circle()" }}
                className="
              hidden 
              group-hover:block
              "
              /> */}
              </div>
            )}
        </div>
        <div className="md:hidden">
          {post &&
            post.author &&
            post.email && (
              <div className="flex flex-row" style={{ flex: 2 }}>
                <img
                  src={imgList[imgNumber]}
                  width="100%"
                  height="300"
                  className="rounded-2xl"
                  style={{ objectFit: "cover", height: "200px" }}
                />
              </div>
            )}
        </div>
      </div>
    </Box >
  );
};

export default BlogListItem;
