/** @jsx jsx */
import { jsx } from "theme-ui";
import BlogListItem from "./blog-list-item";

type ListingProps = {
  posts: {
    title: string
    date: string
    slug: string
    author: string
    email: string
    category: string
    description: string
    tags?: {
      name: string
      slug: string
    }[]
    index: number
    body: string
  }[];
  className?: string;
  showTags?: boolean;
  selectedCategory: string;
};

const checkCategorySame = (posts, selectedCategory) => {
  if (selectedCategory.length >= 1) {

    return posts.filter(post => post.category === selectedCategory)

  }
  return posts
}

const getList = (posts) => {
  return posts.map((post, index) => {


    return (<BlogListItem
      key={post.slug + index}
      post={post}
      showTags={true}
    />)
  })
}


const Listing = ({
  posts,
  selectedCategory,
}: ListingProps) => {


  return (
    <section className="mx-auto">
      {
        getList(checkCategorySame(posts, selectedCategory))
      }
    </section>
  );
};

export default Listing;
