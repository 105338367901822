import * as React from "react";
import banner1 from "/static/img/banner/Banner_002.jpg";
import banner2 from "/static/img/banner/Banner_001.png";

import Slider from "react-slick";
import { Link } from "gatsby";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slide.css";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const bannerimgList = [banner1, banner2];
const bannerTextList = [
  {
    title: "Dev Dive 2022 함수형 개발자로 성장하기",
    content:
      "다양한 기술적 인사이트를 나누고 직접 체험해 볼 수 있는 그린랩스의 첫 번째 오프라인 행사입니다.",
  },
  {
    title: "그린랩스 테크 블로그",
    content: "기술로 세상을 바꾸고자 하는 그린랩스의 이야기입니다.",
  },
];

const bannerLinkList = [

  "https://www.youtube.com/playlist?list=PLOdBZFDkhfV00trSo0SEdHMTaLruDNTzV",
  "#",
];

const swiperSlideList = () => {
  const result: any = [];

  for (let i = 0; i < bannerimgList.length; i++) {
    result.push(
      bannerLinkList[i] !== '#' ?

        (<Link to={bannerLinkList[i]}>

          <img
            src={bannerimgList[i]}
            width="100%"
            height="300px"
            className="rounded-3xl"
            style={{ objectFit: "cover", height: "300px" }}
          />
          <div
            className="
              absolute 
              top-1/2	
              left-1/2	
              banner-text
              text-center	
              text-white
              px-[20px]
              w-full
              md:w-[65%]
              

  "
          >
            <p className="inline-block font-bold	text-2xl mr-[7px]">
              {bannerTextList[i].title}
            </p>

            <span className="block text-lg  m-auto mt-2">
              {bannerTextList[i].content}
            </span>
          </div>

        </Link>) :
        <div className="relative">

          <img
            src={bannerimgList[i]}
            width="100%"
            height="300px"
            className="rounded-3xl"
            style={{ objectFit: "cover", height: "300px" }}
          />
          <div
            className="
                  absolute 
                  top-1/2	
                  left-1/2	
                  banner-text
                  text-center	
                  text-white
                  px-[20px]
                  w-full
                  md:w-[65%]
    
      "
          >
            <p className="inline-block font-bold	text-2xl mr-[7px]">
              {bannerTextList[i].title}
            </p>

            <span className="block text-lg  m-auto mt-2">
              {bannerTextList[i].content}
            </span>
          </div>
        </div>


    );
  }
  return [...result];
};



const TopBanner = () => {
  return (
    <div className="demo-carousel relative max-w-[1920px] mx-auto">

      <Slider {...settings}>

        {swiperSlideList().map((item, index) => (
          <div className="relative" key={index}>{item}</div>
        ))}

      </Slider >
    </div >


  )
};

export default TopBanner;
